import React from 'react';
import VideoTwo from '../video/VideoTwo';
import { FiCheck } from 'react-icons/fi';

const PopupData = [
  {
    id: '01',
    image: 'images/about/about-01.jpg',
    popupLink: ['https://www.youtube.com/watch?v=WA0cewh0-XE']
  }
];

const AboutFour = ({ image }) => {
  return (
    <div className="about-area about-style-4 rn-section-gap">
      <div className="container">
        <div className="row row--40 align-items-center">
          <div className="col-lg-6">
            {PopupData.map((item) => (
              <div className="video-btn" key={item.id}>
                <VideoTwo imagename={`${image}`} galleryItem={item} />
              </div>
            ))}
          </div>
          <div className="col-lg-6 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="inner">
                <h3 className="title">
                  Notre société, <strong>B.S.L Security,</strong> <br /> est
                  basée au Grand-Duché de <strong>Luxembourg</strong>
                </h3>
                <h5>Grâce à la riche et variée expérience en surveillance:</h5>
                <ul className="feature-list">
                  <li>
                    <div className="icon">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title">De nuit</h4>
                    </div>
                  </li>

                  <li>
                    <div className="icon">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title">Détective</h4>
                    </div>
                  </li>
                  {/* <li>
                    <div className="icon">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title">Videosurveillance</h4>
                    </div>
                  </li> */}
                  <li>
                    <div className="icon">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title">Sécurité rapprochée</h4>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title">Sécurité des événements</h4>
                    </div>
                  </li>
                </ul>
                <h5>nos agents visent la perfection pour votre sécurité</h5>
                <div className="about-btn mt--30">
                  <a className="btn-default" href="tel:+352661374881">
                    Contactez Nous
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
