import React from 'react';
import SEO from '../common/SEO';
import '../assets/scss/template/_privacy-policy.scss';
import { Link } from 'react-router-dom';

const Privacy = () => {
  return (
    <>
      <SEO title="Confidentialité" />
      <main className="page-wrapper">
        <div className="container">
          <div className="privacy-policy">
            <h1>Politique de confidentialité</h1>
            <p>Dernière mise à jour : 19/05/2024</p>
            <p>
              Cette politique de confidentialité décrit nos politiques et
              procédures concernant la collecte, l'utilisation et la divulgation
              de vos informations lorsque vous utilisez le service et vous
              informe de vos droits en matière de confidentialité et de la
              manière dont la loi vous protège.
            </p>
            <p>
              Nous utilisons vos données personnelles pour fournir et améliorer
              le service. En utilisant le service, vous acceptez la collecte et
              l'utilisation des informations conformément à cette politique de
              confidentialité. Cette politique de confidentialité a été créée
              avec l'aide du{' '}
              <a
                href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/"
                target="_blank"
                rel="noreferrer"
              >
                Générateur gratuit de politique de confidentialité
              </a>
              .
            </p>
            <h1>Interprétation et définitions</h1>
            <h2>Interprétation</h2>
            <p>
              Les mots dont la première lettre est en majuscule ont des
              significations définies dans les conditions suivantes. Les
              définitions suivantes auront la même signification indépendamment
              de savoir si elles apparaissent au singulier ou au pluriel.
            </p>
            <h2>Définitions</h2>
            <p>Aux fins de cette politique de confidentialité :</p>
            <ul>
              <li>
                <p>
                  <strong>Compte</strong> désigne un compte unique créé pour
                  vous permettre d'accéder à notre service ou à certaines
                  parties de notre service.
                </p>
              </li>
              <li>
                <p>
                  <strong>Affilié</strong> désigne une entité qui contrôle est
                  contrôlée par ou est sous le contrôle commun d'une partie, où
                  « contrôle » signifie la propriété de 50 % ou plus des
                  actions, des intérêts ou d'autres titres de participation
                  ayant droit de vote pour l'élection des administrateurs ou
                  d'autres autorités de gestion.
                </p>
              </li>
              <li>
                <p>
                  <strong>Entreprise</strong> (désignée comme « la Société », «
                  Nous », « Notre » ou « Nos » dans cet Accord) désigne B.S.L
                  Security, 38B, Route de Tréves L-6793 Grevenmacher.
                </p>
              </li>
              <li>
                <p>
                  <strong>Cookies</strong> sont de petits fichiers qui sont
                  placés sur votre ordinateur, appareil mobile ou tout autre
                  appareil par un site web, contenant les détails de votre
                  historique de navigation sur ce site parmi ses nombreuses
                  utilisations.
                </p>
              </li>
              <li>
                <p>
                  <strong>Pays</strong> désigne : Luxembourg
                </p>
              </li>
              <li>
                <p>
                  <strong>Appareil</strong> désigne tout appareil qui peut
                  accéder au service tel qu'un ordinateur, un téléphone portable
                  ou une tablette numérique.
                </p>
              </li>
              <li>
                <p>
                  <strong>Données personnelles</strong> désigne toute
                  information qui se rapporte à un individu identifié ou
                  identifiable.
                </p>
              </li>
              <li>
                <p>
                  <strong>Service</strong> désigne le Site Web.
                </p>
              </li>
              <li>
                <p>
                  <strong>Prestataire de services</strong> désigne toute
                  personne physique ou morale qui traite les données au nom de
                  la Société. Il fait référence à des sociétés ou des individus
                  tiers employés par la Société pour faciliter le service, pour
                  fournir le service au nom de la Société, pour effectuer des
                  services liés au service ou pour aider la Société à analyser
                  comment le service est utilisé.
                </p>
              </li>
              <li>
                <p>
                  <strong>Données d'utilisation</strong> désigne les données
                  collectées automatiquement, générées soit par l'utilisation du
                  service, soit par l'infrastructure du service elle-même (par
                  exemple, la durée d'une visite de page).
                </p>
              </li>
              <li>
                <p>
                  <strong>Site web</strong> désigne B.S.L Security, accessible
                  depuis{' '}
                  <a
                    href="https://boccisecurity.lu"
                    rel="external nofollow noopener noreferrer"
                    target="_blank"
                  >
                    https://boccisecurity.lu
                  </a>
                </p>
              </li>
              <li>
                <p>
                  <strong>Vous</strong> désigne la personne accédant ou
                  utilisant le service, ou la société, ou toute autre entité
                  juridique au nom de laquelle cette personne accède ou utilise
                  le service, le cas échéant.
                </p>
              </li>
            </ul>
            <h1>Collecte et utilisation de vos données personnelles</h1>
            <h2>Types de données collectées</h2>
            <h3>Données personnelles</h3>
            <p>
              Lors de l'utilisation de notre service, nous pouvons vous demander
              de nous fournir certaines informations personnelles identifiables
              qui peuvent être utilisées pour vous contacter ou vous identifier.
              Les informations personnelles identifiables peuvent inclure, mais
              sans s'y limiter :
            </p>
            <ul>
              <li>
                <p>Adresse e-mail</p>
              </li>
              <li>
                <p>Nom et prénom</p>
              </li>
              <li>
                <p>Numéro de téléphone</p>
              </li>
              <li>
                <p>Données d'utilisation</p>
              </li>
            </ul>
            <h3>Données d'utilisation</h3>
            <p>
              Les données d'utilisation sont collectées automatiquement lors de
              l'utilisation du service.
            </p>
            <p>
              Les données d'utilisation peuvent inclure des informations telles
              que l'adresse IP de votre appareil (par exemple, l'adresse IP), le
              type de navigateur, la version du navigateur, les pages de notre
              service que vous visitez, l'heure et la date de votre visite, le
              temps passé sur ces pages, des identifiants d'appareil uniques et
              d'autres données de diagnostic.
            </p>
            <p>
              Lorsque vous accédez au service par le biais d'un appareil mobile,
              nous pouvons collecter certaines informations automatiquement,
              notamment le type de périphérique mobile que vous utilisez, l'ID
              unique de votre appareil mobile, l'adresse IP de votre appareil
              mobile, votre système d'exploitation mobile, le type de navigateur
              Internet mobile que vous utilisez, des identifiants d'appareil
              uniques et d'autres données de diagnostic.
            </p>
            <p>
              Nous pouvons également collecter des informations que votre
              navigateur envoie chaque fois que vous visitez notre service ou
              lorsque vous accédez au service par le biais d'un appareil mobile.
            </p>
            <h3>Technologies de suivi et cookies</h3>
            <p>
              Nous utilisons des cookies et des technologies de suivi similaires
              pour suivre l'activité sur notre service et stocker certaines
              informations. Les technologies de suivi utilisées sont des balises
              Web, des tags et des scripts pour collecter et suivre des
              informations et pour améliorer et analyser notre service. Les
              technologies que nous utilisons peuvent inclure :
            </p>
            <ul>
              <li>
                <strong>Cookies ou Cookies de navigateur.</strong> Un cookie est
                un petit fichier placé sur votre appareil. Vous pouvez demander
                à votre navigateur de refuser tous les cookies ou d'indiquer
                quand un cookie est envoyé. Cependant, si vous n'acceptez pas
                les cookies, vous ne pourrez peut-être pas utiliser certaines
                parties de notre service. À moins que vous n'ayez modifié les
                paramètres de votre navigateur pour qu'il refuse les cookies,
                notre service peut utiliser des cookies.
              </li>
              <li>
                <strong>Balises Web.</strong> Certaines sections de notre
                service et de nos e-mails peuvent contenir de petits fichiers
                électroniques connus sous le nom de balises Web (également
                appelés gifs clairs, tags pixel et gifs un pixel) qui permettent
                à la Société, par exemple, de compter les utilisateurs qui ont
                visité ces pages ou ouvert un e-mail et pour d'autres
                statistiques liées au site Web (par exemple, en enregistrant la
                popularité d'une certaine section et en vérifiant l'intégrité du
                système et du serveur).
              </li>
            </ul>
            <p>
              Les cookies peuvent être des « cookies persistants » ou des «
              cookies de session ». Les cookies persistants restent sur votre
              ordinateur personnel ou votre appareil mobile lorsque vous vous
              déconnectez, alors que les cookies de session sont supprimés dès
              que vous fermez votre navigateur Web. En savoir plus sur les
              cookies sur l'article du{' '}
              <a
                href="https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/#Use_Of_Cookies_And_Tracking"
                target="_blank"
                rel="noreferrer"
              >
                site Web de la politique de confidentialité gratuite
              </a>{' '}
              .
            </p>
            <p>
              Nous utilisons à la fois des cookies de session et des cookies
              persistants aux fins énoncées ci-dessous :
            </p>
            <ul>
              <li>
                <p>
                  <strong>Cookies nécessaires / essentiels</strong>
                </p>
                <p>Type : Cookies de session</p>
                <p>Administrés par : Nous</p>
                <p>
                  Objectif : Ces cookies sont essentiels pour vous fournir les
                  services disponibles sur le site Web et pour vous permettre
                  d'utiliser certaines de ses fonctionnalités. Ils aident à
                  authentifier les utilisateurs et à prévenir une utilisation
                  frauduleuse des comptes d'utilisateur. Sans ces cookies, les
                  services que vous avez demandés ne peuvent pas être fournis,
                  et nous utilisons ces cookies uniquement pour vous fournir ces
                  services.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    Cookies de politique / d'acceptation des avis sur les
                    cookies
                  </strong>
                </p>
                <p>Type : Cookies persistants</p>
                <p>Administrés par : Nous</p>
                <p>
                  Objectif : Ces cookies identifient si les utilisateurs ont
                  accepté l'utilisation de cookies sur le site Web.
                </p>
              </li>
              <li>
                <p>
                  <strong>Cookies de fonctionnalité</strong>
                </p>
                <p>Type : Cookies persistants</p>
                <p>Administrés par : Nous</p>
                <p>
                  Objectif : Ces cookies nous permettent de mémoriser les choix
                  que vous faites lorsque vous utilisez le site Web, comme la
                  mémorisation de vos détails de connexion ou de votre
                  préférence linguistique. Le but de ces cookies est de vous
                  fournir une expérience plus personnelle et d'éviter que vous
                  ayez à saisir à nouveau vos préférences chaque fois que vous
                  utilisez le site Web.
                </p>
              </li>
            </ul>
            <p>
              Pour plus d'informations sur les cookies que nous utilisons et vos
              choix concernant les cookies, veuillez consulter notre Politique
              en matière de cookies ou la section Cookies de notre Politique de
              confidentialité.
            </p>
            <h2>Utilisation de vos données personnelles</h2>
            <p>
              La Société peut utiliser les Données personnelles à des fins
              suivantes :
            </p>
            <ul>
              <li>
                <p>
                  <strong>Pour fournir et maintenir notre service</strong>, y
                  compris pour surveiller l'utilisation de notre service.
                </p>
              </li>
              <li>
                <p>
                  <strong>Pour gérer votre compte :</strong> pour gérer votre
                  inscription en tant qu'utilisateur du service. Les données
                  personnelles que vous fournissez peuvent vous donner accès à
                  différentes fonctionnalités du service qui sont disponibles
                  pour vous en tant qu'utilisateur enregistré.
                </p>
              </li>
              <li>
                <p>
                  <strong>Pour la performance du contrat :</strong> pour
                  respecter les obligations découlant de votre utilisation de
                  notre service et pour vous fournir les fonctionnalités que
                  vous avez demandées. Les données personnelles que nous
                  demandons peuvent être utilisées pour vous contacter afin de
                  résoudre les problèmes liés à votre utilisation de notre
                  service.
                </p>
              </li>
              <li>
                <p>
                  <strong>Pour les notifications :</strong> vous fournir des
                  nouvelles, des offres spéciales et des informations générales
                  sur d'autres biens, services et événements que nous offrons et
                  qui sont similaires à ceux que vous avez déjà achetés ou
                  consultés, sauf si vous avez choisi de ne pas recevoir de
                  telles informations.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    Pour vous fournir des nouvelles, des offres spéciales et des
                    informations générales sur d'autres biens, services et
                    événements que nous proposons et qui sont similaires à ceux
                    que vous avez déjà achetés ou consultés, sauf si vous avez
                    choisi de ne pas recevoir de telles informations.
                  </strong>
                </p>
                <p>
                  Vous pouvez opter pour ne pas recevoir nos communications
                  marketing en suivant le lien de désinscription ou les
                  instructions fournies dans n'importe quel e-mail que nous
                  envoyons.
                </p>
              </li>
              <li>
                <p>
                  <strong>Pour la gestion des paiements :</strong> nous pouvons
                  utiliser les informations que vous fournissez pour effectuer
                  des achats, traiter les paiements et prévenir la fraude.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    Pour répondre aux demandes de support client et aux demandes
                    de renseignements :
                  </strong>{' '}
                  pour gérer et répondre aux demandes de support client et aux
                  requêtes des utilisateurs de manière appropriée.
                </p>
              </li>
              <li>
                <p>
                  <strong>Pour mener des recherches :</strong> nous pouvons
                  utiliser les informations que vous fournissez pour mener des
                  recherches et des analyses afin d'améliorer notre service et
                  notre offre.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    Pour surveiller l'utilisation de notre service :
                  </strong>
                  pour surveiller l'utilisation de notre service et pour
                  détecter, prévenir et résoudre les problèmes techniques.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    Pour vous fournir des informations que vous avez demandées :
                  </strong>{' '}
                  pour vous fournir des informations sur les services que vous
                  avez demandés de notre part.
                </p>
              </li>
              <li>
                <p>
                  <strong>Pour respecter les obligations légales :</strong>
                  pour se conformer aux obligations légales, y compris pour
                  répondre à une assignation à comparaître, à une ordonnance de
                  tribunal ou à d'autres demandes légales de données que nous
                  recevons, ou pour protéger nos droits.
                </p>
              </li>
              <li>
                <p>
                  <strong>Pour des objectifs d'affaires :</strong> pour mener
                  des analyses ou s'engager dans d'autres activités commerciales
                  pour améliorer notre service et notre offre.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    Pour effectuer d'autres fins commerciales telles que
                    l'analyse des données, l'identification des tendances
                    d'utilisation, la détermination de l'efficacité de nos
                    campagnes promotionnelles et pour évaluer et améliorer notre
                    service, nos produits, nos services, nos campagnes de
                    marketing et votre expérience.
                  </strong>
                </p>
              </li>
            </ul>
            <p>
              Nous pouvons partager vos informations personnelles dans les
              situations suivantes :
            </p>
            <ul>
              <li>
                <p>
                  <strong>Avec des fournisseurs de services :</strong> nous
                  pouvons partager vos informations personnelles avec des
                  fournisseurs tiers pour surveiller et analyser l'utilisation
                  de notre service, pour vous montrer des publicités pour
                  soutenir et maintenir notre service, pour vous contacter, pour
                  vous annoncer sur des sites Web de tiers après avoir visité
                  notre service ou pour le traitement des paiements.
                </p>
              </li>
              <li>
                <p>
                  <strong>Pour les transferts commerciaux :</strong> nous
                  pouvons partager ou transférer vos informations personnelles
                  dans le cadre de, ou lors de négociations pour, toute fusion,
                  vente d'actifs de la Société, financement ou acquisition de
                  tout ou partie de nos activités par une autre société.
                </p>
              </li>
              <li>
                <p>
                  <strong>Avec les affiliés :</strong> nous pouvons partager vos
                  informations avec nos affiliés, auquel cas nous exigerons que
                  ces affiliés respectent cette politique de confidentialité.
                  Les affiliés incluent notre société mère et toute autre
                  filiale, coentreprise ou autre société que nous contrôlons ou
                  qui est sous contrôle commun avec nous.
                </p>
              </li>
              <li>
                <p>
                  <strong>Avec des partenaires commerciaux :</strong> nous
                  pouvons partager vos informations avec nos partenaires
                  commerciaux pour vous offrir certains produits, services ou
                  promotions.
                </p>
              </li>
              <li>
                <p>
                  <strong>Avec d'autres utilisateurs :</strong> lorsque vous
                  partagez des informations personnelles ou interagissez dans
                  les espaces publics avec d'autres utilisateurs, ces
                  informations peuvent être consultées par tous les utilisateurs
                  et peuvent être diffusées publiquement à l'extérieur. Si vous
                  interagissez avec d'autres utilisateurs ou vous vous inscrivez
                  via un service de médias sociaux tiers, vos contacts sur le
                  service de médias sociaux tiers peuvent voir votre nom, votre
                  profil, vos photos et vos descriptions de votre activité. De
                  même, d'autres utilisateurs pourront voir les descriptions de
                  votre activité, communiquer avec vous et voir votre profil.
                </p>
              </li>
            </ul>
            <h1>Conservation de vos données personnelles</h1>
            <p>
              La Société ne conservera vos Données personnelles que le temps
              nécessaire aux fins indiquées dans cette politique de
              confidentialité. Nous conserverons et utiliserons vos Données
              personnelles dans la mesure nécessaire pour nous conformer à nos
              obligations légales (par exemple, si nous sommes tenus de
              conserver vos données pour nous conformer aux lois applicables),
              résoudre les litiges et faire respecter nos accords et politiques
              juridiques.
            </p>
            <p>
              La Société conservera également les Données d'utilisation à des
              fins d'analyse interne. Les Données d'utilisation sont
              généralement conservées pour une période plus courte, sauf lorsque
              ces données sont utilisées pour renforcer la sécurité ou améliorer
              la fonctionnalité de notre service, ou si nous sommes légalement
              obligés de conserver ces données pendant des périodes plus
              longues.
            </p>
            <h1>Transfert de vos données personnelles</h1>
            <p>
              Vos informations, y compris vos Données personnelles, sont
              traitées dans les locaux de l'entreprise et dans tout autre lieu
              où se trouvent les parties responsables du traitement. Cela
              signifie que ces informations peuvent être transférées - et
              conservées sur - des ordinateurs situés en dehors de votre État,
              province, pays ou autre juridiction gouvernementale où les lois
              sur la protection des données peuvent différer de celles de votre
              juridiction.
            </p>
            <p>
              Votre consentement à cette politique de confidentialité, suivi de
              votre soumission de telles informations, représente votre accord à
              ce transfert.
            </p>
            <p>
              La Société prendra toutes les mesures raisonnablement nécessaires
              pour s'assurer que vos données sont traitées en toute sécurité et
              conformément à cette politique de confidentialité et que vos
              Données personnelles ne seront transférées à aucune organisation
              ou aucun pays à moins qu'il n'y ait des contrôles adéquats en
              place, y compris la sécurité de vos données et autres informations
              personnelles.
            </p>
            <h1>Divulgation de vos données personnelles</h1>
            <h2>Transactions commerciales</h2>
            <p>
              Si la Société est impliquée dans une fusion, une acquisition ou
              une vente d'actifs, vos Données personnelles peuvent être
              transférées. Nous vous informerons avant que vos Données
              personnelles ne soient transférées et deviennent sujettes à une
              politique de confidentialité différente.
            </p>
            <h2>Conformité à la loi</h2>
            <p>
              La Société peut divulguer vos Données personnelles de bonne foi,
              croyant que cela est nécessaire pour :
            </p>
            <ul>
              <li>
                <p>Se conformer à une obligation légale</p>
              </li>
              <li>
                <p>
                  Protéger et défendre les droits ou la propriété de la Société
                </p>
              </li>
              <li>
                <p>
                  Empêcher ou enquêter sur d'éventuels actes répréhensibles en
                  lien avec le service
                </p>
              </li>
              <li>
                <p>
                  Protéger la sécurité personnelle des utilisateurs du service
                  ou du public
                </p>
              </li>
              <li>
                <p>Protéger contre la responsabilité légale</p>
              </li>
            </ul>
            <h1>Sécurité de vos données personnelles</h1>
            <p>
              La sécurité de vos Données personnelles est importante pour nous,
              mais rappelez-vous qu'aucune méthode de transmission sur Internet,
              ou méthode de stockage électronique, n'est sécurisée à 100 %. Bien
              que nous nous efforcions d'utiliser des moyens commercialement
              acceptables pour protéger vos Données personnelles, nous ne
              pouvons pas garantir leur sécurité absolue.
            </p>
            <h1>Liens vers d'autres sites web</h1>
            <p>
              Notre service peut contenir des liens vers d'autres sites web qui
              ne sont pas exploités par nous. Si vous cliquez sur un lien d'un
              tiers, vous serez dirigé vers le site de ce tiers. Nous vous
              conseillons vivement d'examiner la politique de confidentialité de
              chaque site que vous visitez.
            </p>
            <p>
              Nous n'avons aucun contrôle sur, et n'assumons aucune
              responsabilité quant au contenu, aux politiques de confidentialité
              ou aux pratiques de tout site ou service tiers.
            </p>
            <h1>Modifications de cette politique de confidentialité</h1>
            <p>
              Nous pouvons mettre à jour notre politique de confidentialité de
              temps à autre. Nous vous informerons de toute modification en
              publiant la nouvelle politique de confidentialité sur cette page.
            </p>
            <p>
              Nous vous informerons par e-mail et/ou par un avis bien visible
              sur notre service, avant que la modification ne devienne effective
              et mettrons à jour la « date de dernière mise à jour » en haut de
              cette politique de confidentialité.
            </p>
            <p>
              Nous vous conseillons de consulter périodiquement cette politique
              de confidentialité pour toute modification. Les modifications
              apportées à cette politique de confidentialité sont effectives
              lorsqu'elles sont publiées sur cette page.
            </p>
            <h1>Nous contacter</h1>
            <p>
              Si vous avez des questions sur cette politique de confidentialité,
              vous pouvez nous contacter :
            </p>
            <ul>
              <li>
                <p>Par e-mail : info@bslsecurity.lu</p>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </>
  );
};

export default Privacy;
