import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

const ServiceList = [
  {
    image: '/images/bsl/1.jpg',
    title: 'Service de sécurité',
    description: `B.S.L Security Luxembourg Nos agents de sécurité constituent le pilier de notre société.`
  },

  {
    image: '/images/bsl/2.jpg',
    title: 'Entreprises/Commerces ',
    description: `B.S.L Security Luxembourg Nos agents de sécurité constituent le pilier de notre société.`
  },
  {
    image: '/images/bsl/3.jpg',
    title: 'Particuliers',
    description: `B.S.L Security Luxembourg Nos agents de sécurité constituent le pilier de notre société.`
  },
  {
    image: '/images/bsl/4.jpg',
    title: 'Événements',
    description: `B.S.L Security Luxembourg Nos agents de sécurité constituent le pilier de notre société.`
  }
];
const ServiceTwo = ({ textAlign, cardStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`card-box ${cardStyle} ${textAlign}`}>
              <div className="inner">
                <div className="image">
                  <Link to="#service">
                    <img src={`${val.image}`} alt="card Images" />
                  </Link>
                </div>
                <div className="content">
                  <h4 className="title mb--20">
                    <Link
                      to="#service"
                      dangerouslySetInnerHTML={{ __html: val.title }}
                    ></Link>
                  </h4>
                  <p
                    className="description b1 color-gray mb--0"
                    dangerouslySetInnerHTML={{ __html: val.description }}
                  ></p>
                  <Link
                    className="btn-default btn-small btn-border"
                    to="#service"
                  >
                    Lire Plus
                  </Link>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceTwo;
