import React from 'react';
import SEO from '../common/SEO';
import HeaderOne from '../common/header/HeaderOne';
import AboutFour from '../elements/about/AboutFour';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import TeamFour from '../elements/team/TeamFour';
import TimelineTwo from '../elements/timeline/TimelineTwo';
import Separator from '../elements/separator/Separator';
import ServiceTwo from '../elements/service/ServiceTwo';
import Copyright from './../common/footer/Copyright';

const AboutUs = () => {
  return (
    <>
      <SEO title="About Us" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />
        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-home-1.jpg)`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner pt--80 text-center">
                  <div>
                    <h3 className="rn-sub-badge">
                      <span className="theme-gradient">Qui sommes-nous?</span>
                    </h3>
                  </div>
                  <h1 className="title display-one">
                    B.S.L <br /> B.S.L Security
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <div className="rwt-timeline-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <TimelineTwo classVar="no-gradient" />
              </div>
            </div>
          </div>
        </div>

        <Separator />

        <AboutFour image="./images/about/about-01.jpg" />

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Que pouvons-nous faire pour vous"
                  title="Les services vous fournissent."
                  description="Grâce aux expériences de détective, service de sécurité, surveillance, sécurité rapproché,travail dans les événements nous sommes prêt à être à la perfection de votre sécurité. L’expérience et les formations pour les incendies et les premiers secours sont acquis par nos agents afin que vous vous sentez en toute sécurité. "
                />
              </div>
            </div>
            <ServiceTwo cardStyle="card-style-1" textAlign="text-start" />
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />

        {/* Start Elements Area  */}
        <div className="rwt-team-area rn-section-gap">
          <div className="container">
            <div className="row mb--20">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Nos"
                  title="AGENTS"
                  description="Nos agents de sécurité constituent le pilier de notre société. Qualifiés, formés, ils seront à votre disposition pour vous apporter la meilleure protection Ils vous écouteront et vous soutiendront en toute discrétion"
                />
              </div>
            </div>
            <TeamFour
              column="col-lg-6 col-xl-3 col-md-6 col-12 mt--30"
              teamStyle="team-style-three"
            />
          </div>
        </div>
        {/* End Elements Area  */}

        <Copyright />
      </main>
    </>
  );
};

export default AboutUs;
