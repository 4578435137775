import React from 'react';

const Data = [
  {
    valeurTitle: 'TRUST',
    description:
      'La confiance, l’intégrité et la loyauté sont ancrées dans les gènes de la culture d’entreprise chez B.S.L Security. La confiance réciproque est un élément impératif pour une collaboration saine et efficace.'
  },
  {
    valeurTitle: 'CARE',
    description:
      'Nous souhaitons mettre le bien-être de nos collaborateurs au centre de nos préoccupations. Nous prenons soins de nos agents et de nos clients en proposant, notamment, des postes et des équipements adaptés aux besoins de chacun.'
  },
  {
    valeurTitle: 'AGILITY',
    description:
      'La flexibilité et la capacité d’adaptation sont des valeurs fondamentales pour B.S.L Security. Cette volonté de pouvoir se remettre en question est primordiale pour répondre aux besoins de nos clients de façon réactive et toujours positive.'
  }
];
const CounterUpTwo = ({ textALign, counterStyle, column }) => {
  return (
    <div className="row">
      {Data.map((data, index) => (
        <div className={`${column}`} key={index}>
          <div className={`count-box ${counterStyle} ${textALign}`}>
            <h5 className="title">{data.valeurTitle}</h5>
            <p className="description">{data.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
export default CounterUpTwo;
