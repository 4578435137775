import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
class GoogleMapStyle extends Component {
  static defaultProps = {
    center: {
      lat: 49.531554,
      lng: 5.8916224
    },
    zoom: 16
  };

  render() {
    return (
      <div className="google-map-style-1">
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDM6f57YBnkimgskH7CGI6CIzYSPw1jaRk' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={49.531554}
            lng={5.8916224}
            text="BSL Security"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMapStyle;
