import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here 
import Corporate from "./pages/Corporate";
import AboutUs from "./pages/AboutUs";
import Contact from './elements/contact/Contact';
import Service from './elements/service/Service';

import './assets/scss/style.scss';
import Privacy from './pages/Privacy';

const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    {/* <Route path={`${process.env.PUBLIC_URL + "/corporate"}`} exact component={Splash}/> */}
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Corporate}/>
                    <Route path={`${process.env.PUBLIC_URL + "/about-us"}`} exact component={AboutUs}/>
                    <Route path={`${process.env.PUBLIC_URL + "/services"}`} exact component={Service}/>
                    <Route path={`${process.env.PUBLIC_URL + "/contact"}`} exact component={Contact}/>
                    <Route path={`${process.env.PUBLIC_URL + "/privacy"}`} exact component={Privacy}/>
                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
