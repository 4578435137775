import React from 'react';

const Copyright = () => {
  return (
    <div className="copyright-area copyright-style-one">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-8 col-sm-12 col-12">
            <div className="copyright-left">
              <ul className="ft-menu link-hover">
                <li>
                  <a href="/privacy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/contact">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-4 col-sm-12 col-12">
            <div className="copyright-right text-center text-md-end">
              <p className="copyright-text">
                © B.S.L Security {new Date().getFullYear()} - Developed by{' '}
                <a className="developer-footer" href="https://drhcode.com">
                  DRHCode
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Copyright;
