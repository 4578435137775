import React from 'react';
import ContactForm from './ContactForm';
import GoogleMapStyle from './GoogleMapStyle';
import { FiHeadphones, FiMail, FiMapPin } from 'react-icons/fi';

const ContactOne = () => {
  return (
    <>
      <div className="row row--15">
        <div className="col-lg-12">
          <div className="rn-contact-address mt_dec--30">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">Numéro de téléphone </h4>
                    <p>
                      <a href="tel:+352691903039">+352691903039</a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Notre e-mail</h4>
                    <p>
                      <a href="mailto:info@bslsecurity.lu">
                        info@bslsecurity.lu
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Notre emplacement</h4>
                    <p>
                      144 Av. de la Liberté, 4602 Niederkorn Differdange,
                      Luxembourg{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt--40 row--15">
        <div className="col-lg-7">
          <ContactForm formStyle="contact-form-1" />
        </div>
        <div className="col-lg-5 mt_md--30 mt_sm--30">
          <GoogleMapStyle />
        </div>
      </div>
    </>
  );
};
export default ContactOne;
