import React from 'react';
import { FiFacebook, FiInstagram, FiMapPin, FiPhone } from 'react-icons/fi';

const HeaderTopBar = () => {
  return (
    <div className="header-top-bar">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-12 col-12"></div>
          <div className="col-lg-8 col-md-12 col-12">
            <div className="header-right">
              <div className="address-content">
                <p>
                  <FiMapPin />
                  <span>
                    <a href="https://g.co/kgs/Lk9cEM">
                      144 Av. de la Liberté, 4602 Niederkorn Differdange,
                      Luxembourg{' '}
                    </a>
                  </span>
                </p>
                <p>
                  <FiPhone />
                  <span>
                    <a href="tel:+352691903039">+352691903039</a>
                  </span>
                </p>
              </div>
              <div className="social-icon-wrapper">
                <ul className="social-icon social-default icon-naked">
                  <li>
                    <a
                      href="https://facebook.com/profile.php?id=100091663638424"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FiFacebook />
                    </a>
                  </li>
                  {/* <li>
                    <Link to="twitter.com">
                      <FiTwitter />
                    </Link>
                  </li> */}
                  <li>
                    <a
                      href="https://instagram.com/bslsecurity_luxembourg"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FiInstagram />
                    </a>
                  </li>
                  {/* <li>
                    <Link to="linkdin.com">
                      <FiLinkedin />
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopBar;
