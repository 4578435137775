import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import SectionTitle from '../sectionTitle/SectionTitle';
import ServiceTwo from './ServiceTwo';
import Separator from '../separator/Separator';

const Service = () => {
  return (
    <>
      <SEO title="Services || B.S.L Security" />
      <Layout>
        <div className="main-content">
          {/* Start Service Area  */}
          <div className="rn-service-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Que pouvons-nous faire pour vous"
                    title="Les services vous fournissent."
                    description="Grâce aux expériences de détective, service de sécurité, surveillance, sécurité rapproché,travail dans les événements nous sommes prêt à être à la perfection de votre sécurité. L’expérience et les formations pour les incendies et les premiers secours sont acquis par nos agents afin que vous vous sentez en toute sécurité. "
                  />
                </div>
              </div>
              <ServiceTwo cardStyle="card-style-1" textAlign="text-start" />
            </div>
          </div>
          {/* End Service Area  */}

          <Separator />
        </div>
      </Layout>
    </>
  );
};

export default Service;
