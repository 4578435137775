import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const TimelineData = [
  {
    id: '1',
    title: 'Trust',
    description:
      'La confiance, l’intégrité et la loyauté sont ancrées dans les gènes de la culture d’entreprise chez B.S.L Security.'
  },
  {
    id: '2',
    title: 'Care',
    description:
      'Nous souhaitons mettre le bien-être de nos collaborateurs au centre de nos préoccupations.'
  },
  {
    id: '3',
    title: 'Agility',
    description:
      'La flexibilité et la capacité d’adaptation sont des valeurs fondamentales pour B.S.L Security'
  },
  {
    id: '4',
    title: 'Humanity',
    description:
      'Nous faisons de nos différences des atouts et cette diversité se vit au quotidien sur le terrain dans chacune de nos activités.'
  }
];

const TimelineTwo = ({ classVar }) => {
  return (
    <div className="timeline-style-two bg-color-blackest">
      <div className="row row--0">
        {/* Start Single Progress */}
        {TimelineData.map((data, index) => (
          <div
            className={`col-lg-3 col-md-3 rn-timeline-single ${classVar}`}
            key={index}
          >
            <div className="rn-timeline">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h6 className="title">{data.title}</h6>
              </ScrollAnimation>
              <div className="progress-line">
                <div className="line-inner"></div>
              </div>
              <div className="progress-dot">
                <div className="dot-level">
                  <div className="dot-inner"></div>
                </div>
              </div>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">{data.description}</p>
              </ScrollAnimation>
            </div>
          </div>
        ))}
        {/* End Single Progress */}
      </div>
    </div>
  );
};
export default TimelineTwo;
