import React from 'react';
import SEO from '../common/SEO';
import { Link } from 'react-router-dom';
import { FiArrowRight, FiCheck } from 'react-icons/fi';
import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';
import Copyright from '../common/footer/Copyright';
import ServiceTwo from '../elements/service/ServiceTwo';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import AboutTwo from '../elements/about/AboutTwo';
import CounterUpTwo from '../elements/counterup/CounterUpTwo';
import Separator from '../elements/separator/Separator';
import TeamFour from '../elements/team/TeamFour';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';

const Corporate = () => {
  return (
    <>
      <SEO title="B.S.L Security" />
      <main className="page-wrapper">
        <div className="header-transparent-with-topbar">
          <HeaderTopBar />
          <HeaderOne
            btnStyle="btn-small btn-icon"
            HeaderSTyle="header-not-transparent"
          />
        </div>

        {/* Start Slider area  */}
        <div
          className="slider-area slider-style-2 height-950 bg_image"
          data-black-overlay="2"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-home-1.jpg)`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-start">
                  <h4 className="subtitle">
                    <span className="theme-gradient">
                      B.S.L Security Luxembourg
                    </span>
                  </h4>
                  <h1 className="title display-one">
                    B.S.L Security à votre service.
                  </h1>
                  <ul className="list-icon">
                    <li>
                      <span className="icon">
                        <FiCheck />
                      </span>{' '}
                      CONFIANCE
                    </li>
                    <li>
                      <span className="icon">
                        <FiCheck />
                      </span>{' '}
                      ÉCOUTE
                    </li>
                    <li>
                      <span className="icon">
                        <FiCheck />
                      </span>{' '}
                      AGILITÉ
                    </li>
                    <li>
                      <span className="icon">
                        <FiCheck />
                      </span>{' '}
                      RIGUEUR
                    </li>
                  </ul>
                  <div className="button-group mt--40 mt_sm--20">
                    <Link to="/contact">
                      <button className="btn-default btn-icon" target="_blank">
                        Contactez Nous{' '}
                        <i className="icon">
                          <FiArrowRight />
                        </i>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider area  */}

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Que pouvons-nous faire pour vous"
                  title="Les services vous fournissent."
                  description="Grâce aux expériences de détective, service de sécurité, surveillance, sécurité rapproché,travail dans les événements nous sommes prêt à être à la perfection de votre sécurité. L’expérience et les formations pour les incendies et les premiers secours sont acquis par nos agents afin que vous vous sentez en toute sécurité. "
                />
              </div>
            </div>
            <ServiceTwo cardStyle="card-style-1" textAlign="text-start" />
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />

        <AboutTwo />

        <Separator />
        {/* Start Elements Area  */}
        <div className="rwt-counterup-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle textAlign="text-center" title="Valeurs" />
              </div>
            </div>
            <CounterUpTwo
              column="col-lg-4 col-md-6 col-sm-6 col-12"
              counterStyle="counter-style-2"
              textALign="text-center"
            />
          </div>
        </div>
        {/* End Elements Area  */}

        <Separator />

        {/* Start Elements Area  */}
        <div className="rwt-team-area rn-section-gap">
          <div className="wrapper plr--65 plr_md--15 plr_sm--15">
            <div className="row mb--20">
              <div className="col-lg-12">
                <SectionTitle textAlign="text-center" title="NOS AGENTS" />
              </div>
            </div>
            <TeamFour
              column="col-lg-6 col-xl-3 col-md-6 col-12 mt--30"
              teamStyle="team-style-three"
            />
          </div>
        </div>
        {/* End Elements Area  */}

        <Separator />

        {/* Start Call To Action Area  */}
        <div className="rwt-callto-action-area rn-section-gapBottom">
          <div className="wrapper">
            <CalltoActionFive />
          </div>
        </div>
        {/* End Call To Action Area  */}

        <Copyright />
      </main>
    </>
  );
};

export default Corporate;
